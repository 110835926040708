import getYouTubeId from 'get-youtube-id'
import YouTubeEmbed from 'react-youtube'

export default function YouTube({ data }) {
  const { value } = data
  const { url } = value

  if (!url) return null

  const id = getYouTubeId(url)
  return (
    <div className="w-full my-12 py-16 px-24 bg-black bg-opacity-5">
      <YouTubeEmbed videoId={id} />
    </div>
  )
}
