const useHandleize = (value) => {
  const handle = value
    .toLowerCase()
    .replace(/[^a-z0-9]+/g, '-')
    .replace(/(^-|-$)+/g, '')

  return handle
}

export default useHandleize
