import { urlFor } from '@lib/sanity'
// import Image from 'next/image'
import Link from 'next/link'

import styles from './Figure.module.scss'

export default function ContentImage({ data }) {
  const { value } = data
  const { asset, alt, caption, externalLink, internalLink, enableBG } = value

  const GenerateLink = ({children, link, caption}) => {

    const linkType = link ? (link.includes('http') ? 'external' : 'internal') : ''


    if (link && linkType === 'external') {
      return (
        <a 
        href={`${link}?ref=karmoon`}
        target="_blank"
        rel="noopener noreferrer"
        title={caption}>
          {children}
        </a>
      )
    } else if (link && linkType === 'internal') {
      return link.url ? 
        <Link href={link.url} title={caption} prefetch={false}>
          {children}
        </Link>
         : children
      
    } else {
      return children
    }

  }

  return (
    <figure className={`${styles.wrapper} ${enableBG ? styles.expanded : ''}`}>
      <div>
        <GenerateLink caption={caption} link={externalLink || internalLink || false}>
        {/* <Image
          src={urlFor(asset)
            .quality(95)
            .dpr(1)
            .sharpen(12)
            .width(900)
            .format('webp')
            .url()}
          alt={alt}
          width={800}
          height={800}
        /> */}
        <img src={urlFor(asset)
            .quality(95)
            .dpr(1)
            .sharpen(12)
            .format('webp')
            .url()}
          alt={alt}></img>
        </GenerateLink>
      </div>
      <Caption caption={caption} link={externalLink || internalLink || false} />
    </figure>
  )
}

const Caption = ({ caption, link }) => {
  const linkType = link ? (link.includes('http') ? 'external' : 'internal') : ''

  if (linkType === 'external') {
    return (
      <figcaption>
        <a
          href={`${link}?ref=karmoon`}
          target="_blank"
          rel="noopener noreferrer"
          title={caption}
        >
          {caption}
        </a>
      </figcaption>
    )
  } else if (linkType === 'internal') {
    // prettier-ignore
    <figcaption>
      {link?.url ? (
        <Link href={link.url} title={caption} prefetch={false}>
          {caption}
        </Link>
      ) : (
        <>{caption}</>
      )}
    </figcaption>
  } else {
    return <figcaption>{caption}</figcaption>
  }
}
