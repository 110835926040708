import { hookstate, useHookstate } from '@hookstate/core';

const pageType = hookstate('home')
const menuState = hookstate(false)
const subState = hookstate(false)

/*
   All states are exported as a object containing various setter and getter functions
   Once you have imported to the page / file you need, you can create a var to refer back to then call the functions.
*/

// determines the page type, will be used to change the header without passing props
export const usePageType = () => {
  const state = useHookstate(pageType)
  return {
    //   will accept the type of page
    // example home, work, article
    // where home means the index but also the set index pages like /work /contact
    setPageType(t) {
      return state.set(t)
    },
    getPageType() {
      return state.get()
    },
  }
}

// Get the current nav theme / colour without drilling props
export const useMegaMenu = () => {
  const state = useHookstate(menuState)
  return {
    setMenuState(b) {
      return state.set(b)
    },
    isMenuOpen() {
      return state.get()
    },
    isMenuPaused() {
      if (state.get() == 'paused') {
        return true
      } else {
        return false
      }
    },
  }
}


export const useSubscribeModal = () => {
  const state = useHookstate(subState)
  return {
    close() {
      return state.set(false)
    },
    open() {
      return state.set(true)
    },
    isOpen() {
      return state.get()
    },
  }
}
