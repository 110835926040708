/* eslint-disable no-unused-vars */
import useHandleize from '@hooks/useHandleize'
import { usePageType } from '@state/store'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import styles from './Heading.module.scss'

export default function Heading({ data, level }) {
  const [isCopied, setIsCopied] = useState(false)

  const page = usePageType()
  const pageType = page.getPageType()

  const { value } = data
  const { asPath } = useRouter()

  const origin = typeof window !== 'undefined' ? window.location.origin : ''
  const url = `${origin}${asPath}`

  const title = value.children
    .map((child) => {
      if (child.text) {
        return child.text
      }
      return ''
    })
    .join('')

  const handle = useHandleize(title)

  useEffect(() => {
    if (isCopied) {
      setTimeout(() => {
        setIsCopied(false)
      }, 2000)
    }
  }, [isCopied])

  switch (level) {
    case 1:
      return <h1 id={handle}>{title}</h1>
    case 2:
      switch (pageType) {
        case 'article':
          return (
            <>
              <CopyToast isCopied={isCopied} />
              <h2 className={styles.heading} id={handle}>
                <CopyToClipboard
                  text={`${url}#${handle}`}
                  onCopy={() => {
                    setIsCopied(true)
                  }}
                >
                  <span className={`${styles.hash} hash`}>#</span>
                </CopyToClipboard>
                {title}
              </h2>
            </>
          )
        default:
          return <h2 id={handle}>{title}</h2>
      }
    case 3:
      switch (pageType) {
        case 'article':
          return (
            <>
              <CopyToast isCopied={isCopied} />
              <h3 className={styles.heading} id={handle}>
                <CopyToClipboard
                  text={`${url}#${handle}`}
                  onCopy={() => {
                    setIsCopied(true)
                  }}
                >
                  <span className={`${styles.hash} hash`}>#</span>
                </CopyToClipboard>
                {title}
              </h3>
            </>
          )
        default:
          return <h3 id={handle}>{title}</h3>
      }
    case 4:
      return <h4 id={handle}>{title}</h4>
    case 5:
      return <h5 id={handle}>{title}</h5>
    case 6:
      return <h6 id={handle}>{title}</h6>
    default:
      // TODO: Check if default should be H1?
      return <h1>{title}</h1>
  }
}

const CopyToast = ({ isCopied }) => {
  return (
    <div
      className={`fixed bottom-[30px] left-[30px] bg-black text-white px-4 py-2 rounded-full ${
        isCopied ? `block` : `hidden`
      }`}
    >
      Copied to clipboard
    </div>
  )
}
