import { TextPortable } from '@lib/sanity'
import { useState } from 'react'

import styles from './Accordion.module.scss'

export default function Accordion({ data }) {
  const { value } = data
  const { title, accordionContent: blocks } = value

  if (!blocks) {
    return null
  }

  return (
    <div>
      {title && <h2 className={styles.title}>{title}</h2>}
      <div className="flex flex-col gap-2">
        {blocks &&
          blocks.map((block, index) => {
            const { accordionTab: title, accordionContent: content } = block
            return <Item key={index} title={title} content={content} />
          })}
      </div>
    </div>
  )
}

const Item = ({ title, content }) => {
  const [isActive, setIsActive] = useState(false)
  return (
    <div className={styles.item}>
      <div className={styles.tab} onClick={() => setIsActive(!isActive)}>
        {title && <h3>{title}</h3>}
        {isActive ? '-' : '+'}
      </div>
      {isActive && (
        <div className="py-2">
          {content && <TextPortable blocks={content} />}
        </div>
      )}
    </div>
  )
}
