/* eslint-disable react/display-name */
import { config } from '@lib/config'
import { sanityClient } from '@lib/sanity.server'
import { PortableText } from '@portabletext/react'
import { getFileAsset } from '@sanity/asset-utils'
import { toPlainText } from '@portabletext/react'

import createImageUrlBuilder from '@sanity/image-url'
import sanityImage from '@sanity/image-url'
import Head from 'next/head'
import Link from 'next/link'
import { useRouter } from 'next/router'
// import { createPreviewSubscriptionHook } from 'next-sanity'
import {
  Heading,
  Note,
  Image,
  YouTube,
  Accordion,
  HR,
} from '@components/content'

/**
 * Set up a helper function for generating Image URLs with only the asset reference data in your documents.
 * Read more: https://www.sanity.io/docs/image-url
 **/
export const imageBuilder = sanityImage(sanityClient)
export const urlFor = (source) => createImageUrlBuilder(config).image(source)

// Video
export function videoAssetFor(source) {
  return getFileAsset(source, config)
}

// Live preview hook
// export const usePreviewSubscription = createPreviewSubscriptionHook(config)

// generate the seo structured data for how to guides in article posts
const GetHowToStructuredData = (guide) => {
  // console.log(guide)
  const router = useRouter()
  return {
    '@context': 'https://schema.org',
    '@type': 'HowTo',
    name: `${guide.title}`,
    step: [
      guide.items.map((item, i) => {
        return {
          '@type': 'HowToStep',
          url: `https://karmoon.co.uk/${router.asPath}#${item.step
            .replaceAll(' ', '-')
            .toLowerCase()}`,
          name: `${item.step}`,
          itemListElement: [
            {
              '@type': 'HowToDirection',
              text: `${item.stepContent}`,
            },
          ],
        }
      }),
    ],
  }
}

// generate the seo structured data for Q&A in article posts
const GetQAStructuredData = (qa) => {
  const router = useRouter()

  let _content = qa.items
    ? qa.items.items.map((i, num) => {
        return {
          '@context': 'https://schema.org',
          '@type': 'QAPage',
          mainEntity: {
            '@type': 'Question',
            name: `${i.question}`,
            answerCount: 1,
            acceptedAnswer: {
              '@type': 'Answer',
              text: `${i.answer}`,
              url: `https://karmoon.co.uk/${
                router.asPath
              }/#question-${i.question.replaceAll(' ', '-').toLowerCase()}`,
            },
          },
        }
      })
    : null

  return _content
}

// generate the seo structured data for FAQ in content editor
const GetFAQStructuredData = (qa) => {
  const router = useRouter()

  let _content = qa.items
    ? qa.items.items.map((i, num) => {
        return {
          '@context': 'https://schema.org',
          '@type': 'FAQPage',
          mainEntity: {
            '@type': 'Question',
            name: `${i.question}`,
            answerCount: 1,
            acceptedAnswer: {
              '@type': 'Answer',
              text: `${i.answer}`,
              url: `https://karmoon.co.uk/${
                router.asPath
              }/#faqquestion-${i.question.replaceAll(' ', '-').toLowerCase()}`,
            },
          },
        }
      })
    : null

  return _content
}

// Portable Text
const myPortableTextComponents = {
  types: {
    code: (props) => {
      return (
        <pre className={props.language}>
          <code>{props.code}</code>
        </pre>
      )
    },
    image: (props) => {
      return <Image data={props} />
    },
    contentImage: (props) => {
      return <Image data={props} />
    },
    note: (props) => {
      // console.log('NOTE', props)
      return <Note data={props} />
    },
    youtube: (props) => {
      return <YouTube data={props} />
    },
    horizontalRule: (props) => {
      return <HR data={props} />
    },
    chapterMarker: (props) => {
      return null
    },
    guide: (props) => {
      // add structured data here for posts that include the guide block
      return (
        <>
          <Head>
            <script type="application/ld+json" key="guide-data">
              {JSON.stringify(GetHowToStructuredData(props.value))}
            </script>
          </Head>
          {props.value.items.map((i, num) => {
            return (
              <div key={num} id={i.step.replaceAll(' ', '-').toLowerCase()}>
                <h3>{i.step}</h3>
                <div>{i.stepContent}</div>
              </div>
            )
          })}
        </>
      )
    },
    qa: (props) => {
      // add structured data here for posts that include the q&a block
      return (
        <>
          <Head>
            <script type="application/ld+json" key="qa-data">
              {JSON.stringify(GetQAStructuredData(props.value))}
            </script>
          </Head>
          {props.value.items.items.map((i, num) => {
            return (
              <div
                key={num}
                id={`question-${i.question.replaceAll(' ', '-').toLowerCase()}`}
              >
                <h3>{i.question}</h3>
                <div>{i.answer}</div>
              </div>
            )
          })}
        </>
      )
    },
    faq: (props) => {
      // add structured data here for posts that include the q&a block
      return (
        <>
          <Head>
            <script type="application/ld+json" key="faq-data">
              {JSON.stringify(GetFAQStructuredData(props.value))}
            </script>
          </Head>
          {props.value.items.items.map((i, num) => {
            return (
              <div
                key={num}
                id={`faqquestion-${i.question
                  .replaceAll(' ', '-')
                  .toLowerCase()}`}
              >
                <h3>{i.question}</h3>
                <div>{i.answer}</div>
              </div>
            )
          })}
        </>
      )
    },
    articleAccordion: (props) => {
      return <Accordion data={props} />
    },
  },

  marks: {
    small: (props) => (
      <small className="text-sm leading-6">{props.children}</small>
    ),

    internalLink: (props) => {
      const { slug, type } = props.value
      switch (type) {
        case 'article':
          return (
            <Link href={`/insights/${slug}`} title={props.children}>
              {props.children}
            </Link>
          )
        case 'project':
          return (
            <Link href={`/work/${slug}`} title={props.children}>
              {props.children}
            </Link>
          )
        case 'job':
          return (
            <Link href={`/careers/${slug}`} title={props.children}>
              {props.children}
            </Link>
          )
        case 'page':
          let _slug = ''
          if (slug === 'index') {
            _slug = ''
          } else {
            _slug = slug
          }

          // console.log('SLUG', _slug)

          return (
            <Link href={`/${_slug}`} title={props.children}>
              {props.children}
            </Link>
          )
        default:
          return <a>{props.children}</a>
      }
    },

    link: (props) => {
      const { children, value } = props
      const blank = value.blank
      const url = value.href

      return blank ? (
        <a href={url} target="_blank" rel="noopener noreferrer">
          {children}
        </a>
      ) : (
        <a href={url}>{children}</a>
      )
    },
  },

  block: {
    h2: (props) => {
      return <Heading data={props} level={2} />
    },
    h3: (props) => {
      return <Heading data={props} level={3} />
    },
  },
}

// Block Content Renderer
export const TextPortable = (props) => {
  return (
    <PortableText
      value={props.value || props.blocks}
      components={myPortableTextComponents}
    />
  )
}

export const BlockToText = (props) => {
  // convert portable text to plain text

  return toPlainText(props, { components: myPortableTextComponents })
}
