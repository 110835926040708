import styles from './Note.module.scss'

export default function Note({ data }) {
  const { value } = data
  const { title, text } = value

  return (
    <div
      className={`${styles.wrapper}`}
      style={{ backgroundColor: value.bg || '#ffbdd5' }}
    >
      <div className={styles.fade}>
        <p>
          {title && <span>{title}</span>}
          {text}
        </p>
      </div>
    </div>
  )
}
